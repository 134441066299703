import { gsap } from "gsap";
import { Scroll } from './_app/cuchillo/scroll/Scroll';

import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';
import { VSliderImage } from './scroll/VSliderImage';
import { ScrollItem__SliderDefault } from './scroll/ScrollItem__SliderDefault';
import { ScrollItem__SliderOfertas } from './scroll/ScrollItem__SliderOfertas';
import { ScrollItem__SliderDefaultNoTouch } from './scroll/ScrollItem__SliderDefaultNoTouch';
import { ScrollItem__EspaciosWow } from './scroll/ScrollItem__EspaciosWow';
import { ScrollItem__ListEspaciosWow } from './scroll/ScrollItem__ListEspaciosWow';
import { ScrollItem__FullGallery } from './scroll/ScrollItem__FullGallery';
import { ScrollItem__BookNow } from './scroll/ScrollItem__BookNow';
import { ScrollItem__BillboardHome } from './scroll/ScrollItem__BillboardHome';
import { ScrollItem__Iframe } from './scroll/ScrollItem__Iframe';
import { ScrollItem__Servicios } from './scroll/ScrollItem__Servicios';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isSmartphone, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction } from './_app/cuchillo/core/Interaction';
import { C, GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Legal from './pages/Legal';

import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import { ControllerWindow } from './_app/cuchillo/windows/ControllerWindow';
import Win from './_app/cuchillo/windows/Window';
import WinCookies from './windows/Cmp';
import { WinBookNow } from "./windows/WinBookNow";
import { WinModalGallery } from "./windows/ModalGallery";
//import Preloader from "./layout/Preloader";
import Sidemenu from "./layout/Sidemenu";
import Language from "./_app/cuchillo/utils/Language";

import { KoraButtons } from "./components/KoraButtons";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import LazyLoader from "./_app/cuchillo/loaders/LazyLoader";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import Cookies from "./_app/cuchillo/utils/Cookies";

gsap.registerPlugin(DrawSVGPlugin);
gsap.registerPlugin(Draggable)
gsap.registerPlugin(InertiaPlugin);

export default class Main {
  static stats;
  static isInit = false;
  static isMiraiInit = false;
  static isMiraiTrackingInit = false;

  static init() {
    Basics.id = "KORA_v029"; // ID para cookies 

    if (this.isInit) return;
    this.isInit = true;

    /* CHECK LANG MIRAI: URL vars */
    this.checkMiraiLang();

    // Language.hasRedirectByBrowser = true;
    Language.hasRedirectByBrowser = false;

    if (Language.init()) {
      return;
    }

    KoraButtons.init();
    Header.init();
    Sidemenu.init();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: false }); // Posiciones del cursor (Movimiento, click...), Acciones links, drag...
    ControllerPage.isNative = true;
    ControllerWindow.init(); // Control ventanas

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new LazyLoader()); // Carga/Precarga de imgs
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs, videos
    LoaderController.onComplete = () => Main.setup();

    this.doCuchilloInfo();

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    //Preloader.init();
    LoaderController.init();
  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
    Main.setupMiraiLoader();

    // Carga la mandanga de MIRAI
    /*if (
      document.body.classList.contains("page-booking") ||
      Cookies.get("mirai_loader") == "ok" ||
      (isTouch && !isSmartphone)) {
      Main.setupMiraiLoader();
    } else {
      if (!isSmartphone) {
        document.body.addEventListener(Basics.moveEvent, () => {
          Main.setupMiraiLoader()
        }, { once: true, passive: true })
      }
    }*/
  }

  static checkMiraiLang() {
    const lang = new URLSearchParams(window.location.search).get("lang");
    if (lang) {
      document.body.classList.add(`language-${lang}`);
      const linkHome = GetBy.selector("#Header .logo")[0];

      if (linkHome) linkHome.setAttribute("href", `/${lang}`);
    }
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    EventDispatcher.addEventListener(Win.ON_HIDE, () => {
      Scroll.setEnabled(true);
    });
    EventDispatcher.addEventListener(Win.ON_SHOW, () => {
      Scroll.setEnabled(false);
      Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Sidemenu.ON_HIDE, () => {
      Scroll.setEnabled(true);
    });
    EventDispatcher.addEventListener(Sidemenu.ON_SHOW, () => {
      Scroll.setEnabled(false);
      ControllerWindow.hideAll();
    });
  }

  static setupMiraiLoader() {
    if (this.isMiraiInit) return;

    console.log("SETUP MiraiLoader");

    Cookies.add("mirai_loader", "ok", 720 * 60 * 60 * 1000);
    this.isMiraiInit = true;

    const miraiTag = GetBy.selector("[data-mirai-loader]")[0];

    if (miraiTag) {
      let script = document.createElement('script');
      script.src = miraiTag.getAttribute("href");
      script.setAttribute("data-cookieconsent", "necesary");
      document.body.appendChild(script);
    }
  }

  //Viejo. Deberiamos de borrarlo en la siguiente iteracion.
  //Ya no hace falta comprobar si todo va OK, porque todo se hace desde el loader
  static checkMiraiTracking() {
    if (typeof Mirai !== 'undefined') {
      if (WinCookies.options.level0) {
        if (typeof Mirai.mirai_engine === 'object') {
          Mirai.mirai_engine.main.onProjectLoaded('tracking', () => {
            if (!this.isMiraiTrackingInit) {
              Mirai.mirai_engine.observer.publish('initTracking');
            }
            this.isMiraiTrackingInit = true;
            //WinCookies.activateScriptsConversion();
          });
        } else {
          setTimeout(() => { Main.checkMiraiTracking() }, 100);
        }
      }
    } else {
      setTimeout(() => { Main.checkMiraiTracking() }, 100);
    }
  }

  static resize() {
    Header.resize();
    Sidemenu.resize();
    ControllerPage.resize();
    Scroll.resize();
    Scroll.offsetAnchor = Metrics.WIDTH * .1;
    LoaderController.get('LazyLoader').resize();

    if (isTouch) GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    ControllerPage.loop();
    Header.loop();
    Sidemenu.loop();

    if (Scroll.isScrolling) Scroll.loop();

    LoaderController.get('LazyLoader').loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static doCuchilloInfo() {
    console.log('');
    console.log('');
    console.log('');
    console.log('');
    console.log('%cby Cuchillo', 'background: #000; color: #bada55; padding:25px 100px;');
    console.log('⟶ http://cuchillo.studio');
    console.log('⟶ https://www.instagram.com/_cuchillo');
    console.log('⟶ https://twitter.com/somoscuchillo');
    console.log('⟶ https://twitter.com/mr__corrales');
    console.log('');
    console.log('Gsap by Greenshock');
    console.log('⟶ https://greensock.com');
    console.log('');
    console.log('SVGOMG');
    console.log('⟶ https://jakearchibald.github.io/svgomg/');
    console.log('');
    console.log('Favicon Generator');
    console.log('⟶ https://realfavicongenerator.net');
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init, { once: true });
}
