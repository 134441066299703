const Sizes = {
  RATIO: window.devicePixelRatio,
  RATIO_CANVAS: window.devicePixelRatio,
  SMARTPHONE: 480,
  TABLET_PORTRAIT: 768,
  TABLET_LANDSCAPE: 1024,
  DESKTOP: 1174,
  LARGE_SCREEN: 1400,
};

export { Sizes };
