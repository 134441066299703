import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Metrics } from '../_app/cuchillo/core/Metrics';

class ScrollItem__BillboardHome extends VScroll_Item {
  advantages = [];
  intervalId = null;
  advIndex = 0;
  optionsAnimation = {
    time: 1500
  }

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.advantages = [...GetBy.class('__advantage', __link)];
    this.doAnimation();
    if(isSmartphone) {
      setTimeout(()=>{ this.doScrollPromises();},1000)
      setTimeout(()=>{ this.doScrollPromises();},5000)
     
    }

    this.onShow = () => {
      this.setupAnimation();
    };
    this.onHide = () => {
      window.clearInterval(this.intervalId);
    };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  setupAnimation() {
    this.intervalId = setInterval(() => { this.doAnimation(); }, this.optionsAnimation.time);
  }

  doScrollPromises() {
    const domPromises = GetBy.class("__promises", this.item)[0];
    console.log(domPromises.scrollLeft)
    domPromises.scrollLeft = 0;
  }

  doAnimation() {
    this.advantages.map(advant => {
      advant.classList.remove('--active');
    });
    this.advantages[this.advIndex].classList.add('--active');
    this.advIndex = (this.advIndex + 1) % this.advantages.length;
  }
}

Scroll._registerClass('billboard-home', ScrollItem__BillboardHome);
