import gsap, { Back, Expo, Power2, Power3 } from "gsap";
import { ReverseSubtractEquation } from "three";
import { Basics, isTouch } from "../_app/cuchillo/core/Basics";
import { C, GetBy } from "../_app/cuchillo/core/Element";
import { Metrics } from "../_app/cuchillo/core/Metrics";
import { Ease } from "../_app/cuchillo/utils/Ease";
import { Functions } from "../_app/cuchillo/utils/Functions";
import { Maths } from "../_app/cuchillo/utils/Maths";

export const KoraButtons = {
  _items: [],

  init: function () {
    [...GetBy.selector("[data-kora-button]")].map(dom => this._items.push(new KoraButton(dom, Functions.getId(dom))));
  },

  get(__id) {
    return this._items.filter(btn => btn.id == __id)[0]
  },

  dispose: function (__btn) {
    this._items.filter(btn => !btn.isFixed).map(btn => btn.dispose());
    this._items = this._items.filter(btn => btn.isFixed);
    this._items = [];
  },

  resize: function () {
    this._items.map(btn => btn.resize());
  }
};

export class KoraButton {
  static STATE_LARGE = "large";
  static STATE_LARGE_ICON = "large_icon";
  static STATE_MINI = "mini";
  static STATE_MINI_TEXT = "mini_text";
  static STATE_PROGRESS = "progress";
  static _items = [];

  id;
  container;
  textHolder;
  iconHolder;
  circleHolder;
  circle;
  icon;
  svg
  text;
  textAux;
  strText;
  oldIcon;
  _bg;
  isFixed = false;
  isEnabled = true;
  isHover = false;

  states = {}
  sizes = {
    width: 0,
    height: 0,
    scrollbar: {
      width: 0
    },
    s: {
      width: 0,
      height: 0
    },
    m: {
      width: 0,
      height: 0,
    },
    text: {
      width: 0,
      offset: 0
    },
    circle: {
      scale: .148,
      width: 10,
      s: { scale: .148 },
      m: { scale: .7 },
      l: { scale: 1 },
    }
  }

  _state = "large";
  _total = 9;
  _current = 1;

  get button() { return this.states[this._state]; }

  get current() { return this._current; }
  set current(__current) {
    this._current = __current;
    if (this.hasProgress) {
      GetBy.selector(".progress > .current", this.container)[0].innerHTML = this._current;
    }
    const thumb = GetBy.class("thumb", this.container)[0];
    gsap.to(thumb, { drawSVG: `${0}% ${((this._current - 1) / (this._total - 1)) * 100}%` });
  }

  get total() { return this._total; }
  set total(__total) {
    this._total = __total;
    if (this.hasProgress) {
      GetBy.selector(".progress > .total", this.container)[0].innerHTML = this._total;
    }
  }

  get state() { return this._state; }
  set state(__state) {
    this._state = __state;
    this.isEnabled = true;
    this.states[this._state].activate();
  }

  get width() { return this.sizes.width; }
  set width(__n) {
    this.sizes.width = __n;
    this.container.style.setProperty('--width', `${__n}px`);
  }

  get height() { return this.sizes.height; }
  set height(__n) {
    this.sizes.height = __n;
  }

  get circleScale() { return this.sizes.circle.scale; }
  set circleScale(__n) {
    this.sizes.circle.scale = __n;
    this.container.style.setProperty('--circle-scale', `${this.sizes.circle.scale}`);
  }

  get circleWidth() { return this.sizes.circle.width; }
  set circleWidth(__n) {
    this.sizes.circle.width = __n;
    this.container.style.setProperty('--circle-width', `${this.sizes.circle.width}px`);
  }

  constructor(__container, __id) {
    this.container = __container;
    this.id = __id;
    this.textHolder = GetBy.class("text-holder", this.container)[0];
    this.iconHolder = GetBy.class("icon-holder", this.container)[0];
    this.circleHolder = GetBy.class("circle-holder", this.container)[0];
    this.circle = GetBy.class("circle", this.container)[0];
    this.icon = GetBy.class("icon", this.container)[0];
    this.svg = GetBy.selector("use", this.icon)[0];
    this.text = GetBy.class("text", this.container)[0];
    this.textAux = GetBy.class("text-aux", this.container)[0];
    this.strText = this.text.innerHTML;
    this._bg = GetBy.class("bg", this.container)[0];
    this.hasProgress = !!GetBy.class("progress", this.container)[0]

    this.container.removeAttribute("data-kora-button");

    if (!isTouch) {
      this.container.addEventListener(Basics.mouseOver, e => this.goHover());
      this.container.addEventListener(Basics.mouseOut, e => this.goOut());
    }

    this.states = {
      large_icon: new KoraButton__LargeIcon(this),
      large: new KoraButton__Large(this),
      mini: new KoraButton__Mini(this, this.hasProgress),
      mini_text: new KoraButton__Mini_Text(this)
    }

    this._state = this.container.getAttribute("data-state");

    this.resize();

    this.states[this._state].activate(true);
    this.isEnabled = true;
  }

  goMini() {
    this.state = this.state == KoraButton.STATE_MINI ? KoraButton.STATE_LARGE_ICON : KoraButton.STATE_MINI;
  }

  goMinitext() {
    this.state = this.state == KoraButton.STATE_MINI_TEXT ? KoraButton.STATE_LARGE_ICON : KoraButton.STATE_MINI;
  }

  goHover() {
    this.isHover = true;
    if (this.isEnabled) this.button.hover();
  }

  goOut() {
    this.isHover = false;
    if (this.isEnabled) this.button.out();
  }

  killAll() {
    gsap.killTweensOf(this);
    gsap.killTweensOf(this.text);
    gsap.killTweensOf(this.textAux);
    gsap.killTweensOf(this.textHolder);
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.iconHolder);
    gsap.killTweensOf(this.circleHolder);
    gsap.killTweensOf(this.circle);
    gsap.killTweensOf(this.icon);
    gsap.killTweensOf(this._bg);
  }

  hideText() {
    const temp = this.strText;
    this.changeText("", false, -1);
    this.strText = temp;
  }

  showText() {
    this.changeText(this.strText, false);
  }

  changeIcon(__next, __delay = 0, __direction = 0) {
    const direction = __direction || __next ? 1 : -1;
    __next = __next || this.oldIcon;

    gsap.to(this.iconHolder, {
      rotateZ: 35 * direction, duration: .2, ease: Power2.easeIn, force3D: true, delay: __delay, onComplete: () => {
        gsap.set(this.iconHolder, { rotateZ: -35 * direction, force3D: true });
        this.oldIcon = this.svg.getAttribute("xlink:href").split("#svg-")[1];
        if (__next) {
          const pathIcon = this.svg.getAttribute("xlink:href").split("#")[0];
          this.svg.setAttribute("xlink:href", `${pathIcon}#svg-${__next}`);
        }
        gsap.to(this.iconHolder, { rotateZ: 0, duration: 1, ease: Power3.easeOut, delay: 0, force3D: true });
      }
    });
  }

  changeLink(__link) {
    if (__link) {
      this.container.classList.remove("--disabled");
      this.container.setAttribute("href", __link);
    } else {
      this.container.classList.add("--disabled");
    }
  }

  changeTextDirect(__s) {
    this.text.innerHTML = __s;
    //this.container.setAttribute("aria-label", __s);
  }

  changeText(__s, __isHover = false, __direction = 1) {
    if (this.textAux) {
      gsap.killTweensOf(this);
      gsap.killTweensOf(this.text);
      gsap.killTweensOf(this.textAux);
      C.remove(this.text);
      this.text = this.textAux;
      this.textAux = null;
    }

    this.textAux = document.createElement("SPAN");
    this.textAux.innerHTML = __s;
    this.textAux.style.opacity = 0;

    if (__isHover) {
      this.textAux.classList.add("--hover")
    }

    this.textHolder.appendChild(this.textAux);

    if (__s != "") {
      this.strText = __s;
      this.sizes.text.width = this.textAux.offsetWidth;
      this.sizes.m.width = this.sizes.text.width;
    }

    gsap.to(this.text, { y: -this.sizes.text.offset * __direction, ease: Power2.easeIn, duration: .2 });
    gsap.to(this.text, { opacity: 0, ease: Power2.easeIn, duration: .2, delay: 0 });
    gsap.from(this.textAux, { y: this.sizes.text.offset * __direction, ease: Back.easeOut, duration: .6, delay: .2 });
    gsap.to(this.textAux, { opacity: 1, ease: Power2.easeOut, duration: .2, delay: .2 });

    if (this.state != "mini") {
      gsap.to(this, {
        width: this.sizes.m.width, ease: Power3.easeOut, duration: .8, onComplete: () => {
          C.remove(this.text);
          this.text = this.textAux;
          this.textAux = null;
        }
      });
    }
  }

  resize() {
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.sizes.s.width = this.sizes.height;
    this.sizes.s.height = this.sizes.height;
    this.sizes.text.width = this.text.offsetWidth;
    this.sizes.text.offset = Metrics.parseSize("10fpx");
    this.sizes.m.width = this.sizes.text.width;
    this.sizes.m.height = this.sizes.height;
    this.sizes.scrollbar.width = Metrics.parseSize("220fpx");
    this.circleWidth = this.sizes.m.height;

    this.button.resize();
  }

  dispose() {
    this.id = null;
    this.container = null;
    this.textHolder = null;
    this.iconHolder = null;
    this.circleHolder = null;
    this.circle = null;
    this.icon = null;
    this.text = null;
    this.textAux = null;
    this.strText = null;
    this._bg = null;
    this.isFixed = null;
    this.isEnabled = null;
    this.states = {}
    this.sizes = {}
  }
}

class KoraButton__Large {
  button;
  items = {}

  constructor(__button, __items) {
    this.button = __button;
    this.items = __items;
  }

  changeText(__text) {
    this.button.changeText(__text);
  }

  activate(__isDirect) {
    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.iconHolder);
    gsap.killTweensOf(this.button.textHolder);

    if (__isDirect) {
      gsap.set(this.button, { width: this.button.sizes.m.width });
      gsap.set(this.button, { circleScale: this.button.sizes.circle.s.scale });
      gsap.set(this.button.textHolder, { x: 0, force3D: true });
      gsap.set(this.button.iconHolder, { rotateZ: 25, force3D: true });
      return;
    }

    this.button.showText();
    this.button.isEnabled = false;

    gsap.to(this.button, { width: this.button.sizes.m.width, ease: Back.easeOut, duration: .8 });
    gsap.to(this.button, { circleWidth: this.button.sizes.s.width, circleScale: this.button.sizes.circle.s.scale, duration: 1, ease: Back.easeOut });
    gsap.to(this.button.iconHolder, { rotateZ: 35, duration: .3, force3D: true });
    gsap.to(this.button.textHolder, {
      x: 0, duration: 1, opacity: 1, ease: Back.easeOut, force3D: true, onComplete: () => {
        this.button.isEnabled = true;
      }
    });
  }

  hover() {
    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.iconHolder);
    gsap.killTweensOf(this.button._bg);

    gsap.to(this.button, { circleScale: this.button.sizes.circle.m.scale, duration: 1, ease: Back.easeOut });
    gsap.to(this.button.textHolder, { x: this.button.sizes.text.offset, duration: 1, ease: Back.easeOut, force3D: true });
    gsap.set(this.button.iconHolder, { rotateZ: -35, force3D: true });
    gsap.to(this.button.iconHolder, { rotateZ: 0, duration: 1, ease: Back.easeOut, delay: 0, force3D: true });
    gsap.to(this.button._bg, { scale: 1.1, duration: .4, force3D: true });
    gsap.to(this.button._bg, { scale: 1.0, duration: .4, delay: .1, force3D: true });
  }

  out() {
    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.textHolder);
    gsap.killTweensOf(this.button.circle);

    gsap.to(this.button, { circleScale: this.button.sizes.circle.s.scale, duration: 1, ease: Back.easeOut });
    gsap.to(this.button.textHolder, { x: 0, duration: 1, ease: Back.easeOut, force3D: true });
    gsap.to(this.button.iconHolder, { rotateZ: 35, duration: .3, force3D: true });
  }

  resize() {
    this.button.width = this.button.sizes.m.width;
  }
}

class KoraButton__LargeIcon {
  button;
  items = {}

  constructor(__button, __items) {
    this.button = __button;
    this.items = __items;
  }

  changeText(__text) {
    this.button.changeText(__text);
  }

  activate(__isDirect) {
    this.button.isEnabled = false;

    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.textHolder);
    gsap.killTweensOf(this.button.iconHolder);

    if (__isDirect) {
      gsap.set(this.button, { width: this.button.sizes.m.width });
      gsap.set(this.button, { circleScale: this.button.sizes.circle.m.scale });
      gsap.set(this.button.textHolder, { x: this.button.sizes.text.offset, force3D: true });
      gsap.set(this.button.iconHolder, { rotateZ: 0, force3D: true });
      return;
    }

    this.button.showText();

    gsap.to(this.button.iconHolder, { rotateZ: 0, duration: .3, force3D: true });
    gsap.to(this.button, { width: this.button.sizes.m.width, ease: Back.easeOut, duration: .8 });
    gsap.to(this.button, { circleScale: this.button.sizes.circle.m.scale, duration: 1, ease: Back.easeOut });
    gsap.to(this.button.textHolder, {
      x: this.button.sizes.text.offset, duration: 1, opacity: 1, ease: Back.easeOut, force3D: true, onComplete: () => {
        this.button.isEnabled = true;
      }
    });
  }

  hover() {
    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.textHolder);
    gsap.killTweensOf(this.button.iconHolder);
    gsap.killTweensOf(this.button.circle);
    gsap.killTweensOf(this.button.circleHolder);

    this.button.changeText(this.button.strText, true);

    //gsap.to(this.button, {circleScale: this.button.sizes.circle.m.scale, duration:1, ease:Back.easeOut});
    gsap.to(this.button.textHolder, { x: this.button.sizes.text.offset, duration: 1, ease: Back.easeOut, force3D: true });

    gsap.to(this.button, { circleWidth: this.button.sizes.m.width, duration: .8, delay: .0, ease: Expo.easeOut });
    gsap.to(this.button, { circleScale: 1, duration: .3, ease: Power3.easeOut, force3D: true });

    gsap.to(this.button.icon, { scale: 1.4, duration: .4, force3D: true });
    gsap.to(this.button.icon, { scale: 1.0, duration: .4, delay: .1, force3D: true });

    gsap.to(this.button._bg, { scale: 1.1, duration: .4, force3D: true });
    gsap.to(this.button._bg, { scale: 1.0, duration: .4, delay: .1, force3D: true });
  }

  out() {
    gsap.killTweensOf(this.button);

    this.button.changeText(this.button.strText, false, -1);
    gsap.to(this.button, { circleScale: this.button.sizes.circle.m.scale, duration: .4, ease: Power2.easeOut });
    gsap.to(this.button, { circleWidth: this.button.sizes.m.height, duration: .4, ease: Power2.easeOut });
  }

  resize() {
    this.button.width = this.button.sizes.m.width;
  }
}

class KoraButton__Mini {
  button;
  hasProgress;
  isShowEnded = true;
  idTimer;

  constructor(__button, __hasProgress) {
    this.button = __button;
    this.hasProgress = __hasProgress;
  }

  changeText(__text) {
    this.button.changeText(__text);
  }

  activate(__isDirect) {
    this.button.isEnabled = false;

    gsap.killTweensOf(this.button);
    gsap.killTweensOf(this.button.icon);
    gsap.killTweensOf(this.button.textHolder);
    gsap.killTweensOf(this.button.iconHolder);

    if (__isDirect) {
      this.button.changeTextDirect("");
      gsap.set(this.button, { circleScale: this.button.sizes.circle.l.scale });
      gsap.set(this.button, { width: this.button.sizes.s.width });
      gsap.set(this.button.icon, { scale: .1, force3D: true });
      gsap.set(this.button.icon, { scale: 1.0, force3D: true });
      gsap.set(this.button.iconHolder, { rotateZ: 0, force3D: true });
      gsap.set(this.button.textHolder, { opacity: 0, force3D: true });
      return;
    }

    this.button.hideText();
    gsap.to(this.button, { circleWidth: this.button.sizes.s.width, ease: Ease.EASE_BACK_IN_QUAD_OUT, duration: .8 });
    gsap.to(this.button, { circleScale: this.button.sizes.circle.l.scale, duration: .7, ease: Back.easeInOut, delay: .1 });
    gsap.to(this.button, { width: this.button.sizes.s.width, ease: Ease.EASE_BACK_IN_QUAD_OUT, duration: .8 });
    gsap.to(this.button.icon, { scale: .1, duration: .4, force3D: true });
    gsap.to(this.button.iconHolder, { rotateZ: 0, duration: 1, ease: Back.easeOut, delay: 0.5, force3D: true });
    gsap.to(this.button.icon, {
      scale: 1.0, duration: 1, delay: .2, ease: Back.easeOut, force3D: true, onComplete: () => {
        this.button.isEnabled = true;
      }
    });
  }

  hover() {
    this.button.killAll();
    clearTimeout(this.idTimer);

    if (this.hasProgress) {
      gsap.to(this.button, {
        width: this.button.sizes.scrollbar.width, ease: Ease.EASE_CUCHILLO_IN_OUT, duration: .5, onComplete: () => {
          this.button.container.classList.add("--scroll-enabled");
        }
      });
    } else { }
    gsap.to(this.button.circle, { scale: 1.2, duration: .4, force3D: true });
    gsap.to(this.button.circle, { scale: 1.0, duration: .4, delay: .1, force3D: true });

    gsap.to(this.button.icon, { scale: .4, duration: .4, force3D: true });
    gsap.to(this.button.icon, { scale: 1.0, duration: .4, delay: .1, force3D: true });
  }

  out() {
    this.button.killAll();
    if (this.hasProgress) {
      this.idTimer = setTimeout(() => {
        this.button.container.classList.remove("--scroll-enabled");
        gsap.to(this.button, { width: this.button.sizes.s.width, ease: Ease.EASE_CUCHILLO_IN_OUT, duration: .4, delay: 0 });
      }, 100)
    }
  }

  resize() {
    this.button.width = this.button.sizes.s.width;
  }
}

class KoraButton__Mini_Text extends KoraButton__Mini {
  constructor(__button, __items) {
    super(__button, __items);
  }

  hover() {
    this.button.killAll();
    this.button.showText();
    gsap.to(this.button.iconHolder, { rotateZ: 0, duration: .3, force3D: true });
    gsap.to(this.button, { width: this.button.sizes.m.width, ease: Back.easeOut, duration: .8 });
    gsap.to(this.button, { circleScale: this.button.sizes.circle.m.scale, duration: 1, ease: Back.easeOut });
    gsap.to(this.button.textHolder, { x: this.button.sizes.text.offset, duration: 1, opacity: 1, ease: Back.easeOut, force3D: true });
  }

  out() {
    this.button.killAll();
    this.button.hideText();
    gsap.to(this.button, { circleWidth: this.button.sizes.m.height, duration: .4, ease: Power2.easeOut });
    gsap.to(this.button, { circleScale: this.button.sizes.circle.l.scale, duration: .7, ease: Back.easeInOut, delay: .1 });
    gsap.to(this.button, { width: this.button.sizes.s.width, ease: Ease.EASE_BACK_IN_QUAD_OUT, duration: .8 });
    gsap.to(this.button.icon, { scale: .1, duration: .4, force3D: true });
    gsap.to(this.button.iconHolder, { rotateZ: 0, duration: 1, ease: Back.easeOut, delay: 0.5, force3D: true });
    gsap.to(this.button.icon, { scale: 1.0, duration: 1, delay: .2, ease: Back.easeOut });
  }
} 
