import { Power3 } from "gsap";

import _Header from '../_app/cuchillo/layout/Header';
import { KoraButton, KoraButtons } from '../components/KoraButtons';

export default class Header extends _Header {
  static isFixed = false;
  static btnBook;
  static optionsText = {
    texts: this.container.getAttribute("data-book-text").split(";"),
    actual: 0,
    time1: 6000,
    time2: 2000,
    next: () => {
      this.optionsText.actual = this.optionsText.actual + 1 === this.optionsText.texts.length ? 0 : this.optionsText.actual + 1;
      return this.optionsText.texts[this.optionsText.actual];
    }
  }

  //static show(__call) {}
  //static hide(__call) {}

  static init() {
    super.init();
    this.btnBook = KoraButtons.get("HeaderButtonBook");
    this.changeText();
    this.directShow();
  }

  static changeText() {
    setTimeout(() => {
      if (this.btnBook.isHover) {
        this.changeText();
        return;
      }
      this.btnBook.changeText(this.optionsText.next());
      this.changeText();
    },
    this.optionsText.actual%2 === 0 ? this.optionsText.time1 : this.optionsText.time2);
  }
}