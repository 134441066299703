import { gsap, Power2, Power3 } from 'gsap';
import { KoraButton, KoraButtons } from '../components/KoraButtons';
import { KoraTabSelector } from '../components/KoraTabSelector';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';

import { GetBy } from '../_app/cuchillo/core/Element';
import { FormValidator } from '../_app/cuchillo/forms/FormValidator';
import VScroll from '../_app/cuchillo/scroll/VScroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Win from '../_app/cuchillo/windows/Window';
import Main from '../main';

class Booknow_Item extends VScroll_Item {
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this.hasHiddenEnabled = false;
    }
}

class BookNow extends Win {
    _form;
    _tabs;
    _btns;
    _tabsSelector;
    _title1;
    _title2;
    options;

    constructor(__container) {
        super(__container, 'book-now');

        if (localStorage.getItem('isBookOpen') == true) {
            this.directShow();
        } else {
            this.directHide();
        }

        this._tabsSelector = new KoraTabSelector(GetBy.selector('[data-kora-tab-selector]', __container)[0]);
        this._tabsSelector.changeTab(0);

        this.options = {
            container: this.container,
            isMain: false,
            itemClass: Booknow_Item
        }

        this._form = new FormValidator(GetBy.class('__form', __container)[0]);

        this._tabs = [...GetBy.class('__tab', __container)];
        this._btns = [...GetBy.class('__btn', __container)];

        this._title1 = GetBy.class('__title-1', __container)[0];
        this._title2 = GetBy.class('__title-2', __container)[0];

        this._toggle = (e) => this.toggle(e.target);

        let indexBtn = 0;
        this._btns.map(btn => {
            btn.setAttribute("data-index", indexBtn);
            btn.addEventListener(Basics.clickEvent, this._toggle)
            indexBtn++;
        });

        /* BUG RESIZE */
        if (!isSmartphone) {
            this.engine = new VScroll(this.options);
            this.engine.addAll('[scroll-book-item]');

            setTimeout(() => {
                this.engine.resize();
            }, 200);
        }

        this._loop = () => this.loop();
    }

    initTabs() {
        this._tabs.map(tab => {
            if (!tab.classList.contains('--active')) {
                gsap.set(tab, { opacity: 0 });
            }
        });
    }

    show(__d = 0) {
        if (Basics.tempValue !== null) {
            this.directToggle(Basics.tempValue);
            Basics.tempValue = null;
        }

        localStorage.setItem('isBookOpen', 'true');
        super.show(__d);
    }

    show__effect(__d = 0) {
        // KoraButtons.get("HeaderButtonBook").container.classList.add("__close");
        // KoraButtons.get("HeaderButtonBook").state = KoraButton.STATE_MINI;
        // KoraButtons.get("HeaderButtonBook").changeIcon("icon-cross", .2);

        gsap.set(this._title1, { y: '-100%' });
        gsap.set(this._title2, { y: '100%' });
        gsap.set(this.container, { opacity: 0 });

        let delay = .4;
        gsap.to(this.container, { opacity: 1, duration: .2, delay, ease: Power2.easeIn, onComplete: () => { this.afterShow() } });

        delay += .2;
        gsap.to(this._title1, {
            y: 0,
            duration: 1.1,
            ease: Power3.easeOut,
            delay
        });

        delay += .1;
        gsap.to(this._title2, {
            y: 0,
            duration: 1.1,
            ease: Power3.easeOut,
            delay
        });
    }

    afterShow() {
        this.enableScroll();
        super.afterShow();
    }

    hide__effect(__d = 0) {
        //localStorage.setItem('isBookOpen', 'hide');
        // KoraButtons.get("HeaderButtonBook").container.classList.remove("__close");
        // KoraButtons.get("HeaderButtonBook").state = KoraButton.STATE_LARGE_ICON;
        // KoraButtons.get("HeaderButtonBook").changeIcon();

        gsap.to(this._title1, {
            y: '-100%',
            duration: .6,
            ease: Power3.easeIn
            // force3D: true
        });

        gsap.to(this._title2, {
            y: '100%',
            duration: .6,
            ease: Power3.easeIn
            // force3D: true,
        });

        gsap.to(this.container, { opacity: 0, duration: .4, delay: 0, ease: Power2.easeOut, onComplete: () => { this.afterHide(); } });
    }

    afterHide() {
        this.disableScroll();
        super.afterHide();
    }

    directHide() {
        this.disableScroll();
        gsap.set(this.container, { opacity: 0 });
        super.directHide();
    }

    toggle(btn) {
        this._tabsSelector.changeTab(Number(btn.getAttribute("data-index")));

        this._tabs.map(tab => {
            if (btn.dataset.target === tab.id) {
                gsap.set(tab, { opacity: 0 });
                gsap.delayedCall(.2, () => {
                    tab.classList.add('--active');
                })
                gsap.to(tab, {
                    opacity: 1,
                    duration: .3,
                    delay: .3,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        if (this.engine) {
                            this.engine.resize();
                        }
                    }
                });
            } else {
                gsap.to(tab, {
                    opacity: 0,
                    duration: .2,
                    ease: Power2.easeOut,
                    onComplete: () => {
                        tab.classList.remove('--active');
                    }
                });
            }
        });

        this._btns.map(b => {
            if (btn === b) {
                b.classList.add('--active');
            } else {
                b.classList.remove('--active');
            }
        });
    }

    directToggle(id) {
        this._tabs.map(tab => {
            if (id === tab.id) {
                tab.classList.add('--active');
                gsap.set(tab, { opacity: 1 });
            } else {
                gsap.set(tab, { opacity: 0 });
                tab.classList.remove('--active');
            }
        });

        this._btns.map(b => {
            if (id === b.dataset.target) {
                console.log('tab active', id, b);
                this._tabsSelector.changeTab(Number(b.dataset.index));
            }
        });

        if (this.engine) this.engine.resize();
    }

    enableScroll() {
        if (this.engine && !this.engine.enabled) {
            gsap.ticker.add(this._loop);
            this.engine.enabled = true;
        }
    }

    disableScroll() {
        if (this.engine && this.engine.enabled) {
            this.engine.enabled = false;
            gsap.ticker.remove(this._loop);
        }
    }

    loop() {
        if (this.engine && this.engine.enabled) {
            this.engine.loop();
        }
    }

    dispose() {
        if (this._form) this._form.dispose();
        if (this.engine) this.engine.dispose();
        this._btns.map(btn => { btn.removeEventListener(Basics.clickEvent, this._toggle) });
        super.dispose();
    }

    resize() {
        super.resize();

        if (this._tabsSelector) this._tabsSelector.resize();
    }
}

export const WinBookNow = new BookNow(GetBy.id('BookNow'));