import { Basics } from "../core/Basics";

export default class Cookies {
  static add(__var, __value, __time = 999999999999, isAbsolute = false) {
    const prefix = isAbsolute? '' : Basics.id + "_";
    var now = new Date();
    now.setTime(now.getTime() + __time);
    
    document.cookie = `${prefix}${__var}=${encodeURI(__value)}; expires=${now.toUTCString()}; path=/`;
  }

  static get(name) {
    let result = document.cookie.match(`(^|[^;]+)\s*${Basics.id}_${name}\s*=\s*([^;]+)`)
    return result ? decodeURI(result.pop()) : ""
  }

  static remove(__var) {
    var now = new Date();
    now.setTime(now.getTime() + 999999999999);
    document.cookie = Basics.id + `_${__var}==; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
