import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import { gsap } from "gsap";
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';

class ScrollItem__ListEspaciosWow extends VScroll_Item {
    _call;
    _bullets;
    _thumbnails;
    hasHiddenEnabled = false;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._click = (e) => this.click(e);
        this._bullets = [...GetBy.class('__bullet')];
        this.addEvents();

        this._call = () => this.loop();
        this.onShow = () => {
            gsap.ticker.add(this._call);
        };

        this.onHide = () => {
            gsap.ticker.remove(this._call);
        };
    }

    click(e) {
        Scroll.gotoAnchor(e.target.dataset.target)
    }

    addEvents() {
        for (let i = 0; i < this._bullets.length; i++) {
            this._bullets[i].addEventListener(Basics.clickEvent, this._click);
        }
    }

    removeEvents() {
        for (let i = 0; i < this._bullets.length; i++) {
            this._bullets[i].removeEventListener(Basics.clickEvent, this._click);
        }
    }

    loop() {
        if (isSmartphone) return;

        const p = 1 - this.progressInside;
        const i = Maths.clamp(Math.ceil(Maths.lerp(0, this._bullets.length - 1, p)), 0, this._bullets.length);

        for (let index = 0; index < this._bullets.length; index++) {
            const b = this._bullets[index];

            if (index < i) b.classList.add('--active');
            else b.classList.remove('--active');
        }
    }

    dispose() {
        gsap.ticker.remove(this._call);
        this.removeEvents();
        super.dispose();
    }
}

Scroll._registerClass('ListEspaciosWow', ScrollItem__ListEspaciosWow);
