import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isMobile, isSmartphone } from '../_app/cuchillo/core/Basics';
import { Functions } from '../_app/cuchillo/utils/Functions';
import iframeResize from '@iframe-resizer/parent';

class ScrollItem__Iframe extends VScroll_Item {
 
  _iframe;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._iframe = GetBy.selector("iframe", this.item)[0];

    iframeResize({
      license: 'GPLv3',  // Licencia requerida para proyectos open source
      waitForLoad: true,  // Espera hasta que el iframe esté completamente cargado
      
    }, `#${Functions.getId(this._iframe)}`);
  }
}

Scroll._registerClass('scroll-iframe', ScrollItem__Iframe);
