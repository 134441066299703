import { Draggable } from "gsap/Draggable";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isSmartphoneOnlyWidth } from '../_app/cuchillo/core/Basics';

class ScrollItem__SliderOfertas extends VScroll_Item {
    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (isSmartphoneOnlyWidth) {
            if (!GetBy.selector("[scroll-slider-item]", __link)[0]) return;

            const x1 = GetBy.selector("[scroll-slider-item]", __link)[0].getBoundingClientRect().left;
            const x2 = GetBy.selector("[scroll-slider-item]", __link)[1].getBoundingClientRect().left;
            const gridWidth = x2 - x1;

            Draggable.create(GetBy.class("__holder", __link)[0], {
                type: "x",
                edgeResistance: 0.85,
                dragResistance: .1,
                bounds: GetBy.class("__slider", __link)[0],
                inertia: true,
                snap: {
                    x: function (endValue) {
                        return Math.round(endValue / gridWidth) * gridWidth;
                    }
                }
            });
        }
    }

    resize(w, h) {
        super.resize(w, h);
    }

    loop() { }

    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('SliderOfertas', ScrollItem__SliderOfertas);
