import gsap, { Power2, Power3 } from 'gsap';

import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Ease } from '../_app/cuchillo/utils/Ease';
import { KoraButton, KoraButtons } from '../components/KoraButtons';

export default class Sidemenu extends _Sidemenu {
  static positions;
  static sizes;
  static content = GetBy.class('content', this.container)[0];
  static fakeButton = GetBy.class('fake-button', this.container)[0];
  static texts = Array.from(GetBy.class('__text', this.container));

  static init() {
    this.setupSizes();
    Sidemenu.directHide();

    this.btnToggle = KoraButtons.get("HeaderButtonToogle");
  }

  static show__effect(__d = 0) {
    gsap.killTweensOf(this.content);
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.fakeButton);

    gsap.set(this.container, { alpha: 0 });
    gsap.set(this.texts, { y: '100%' });

    this.btnToggle.state = KoraButton.STATE_MINI;
    this.btnToggle.changeIcon('asset-cross', .2);

    if (isSmartphone) {
      gsap.to(this.container, {
        alpha: 1,
        duration: .4,
        delay: .0,
        ease: Power3.easeInOut
      });
    } else {
      // this.btnToggle.container.style.pointerEvents = "all";
      // gsap.to(this.btnToggle.container, {
      //   alpha: 1,
      //   duration: .4,
      //   delay: .2,
      //   ease: Power2.easeOut
      // });

      gsap.set(this.container, { alpha: 1 });

      gsap.to(this.content, {
        x: this.positions.xShow,
        duration: .9,
        delay: 0,
        ease: Ease.EASE_CUCHILLO_IN_OUT
      });

      gsap.to(this.fakeButton, {
        alpha: .3,
        duration: .6,
        delay: 0,
        ease: Power3.easeInOut
      });
    }

    this.texts.map((item, i) => {
      gsap.to(item, {
        y: 0,
        duration: 1,
        delay: __d + 0.05 * i,
        ease: Power2.easeInOut
      });
    });

    gsap.delayedCall(0.05 * this.texts.length, () => { this.afterShow(); });
  }

  static hide__effect(__d = 0) {
    gsap.killTweensOf(this.content);
    gsap.killTweensOf(this.container);
    gsap.killTweensOf(this.fakeButton);

    this.btnToggle.state = KoraButton.STATE_MINI;
    this.btnToggle.changeIcon();

    this.texts.map((item, i) => {
      gsap.to(item, {
        y: '100%',
        duration: 0.4,
        delay: __d,
        ease: Power2.easeInOut
      });
    });

    if (isSmartphone) {
      gsap.to(this.container, {
        alpha: 0,
        delay: 0.3,
        duration: 0.4,
        delay: __d,
        ease: Power2.easeOut,
        onComplete: () => {
          this.afterHide();
        }
      });
    } else {
      // this.btnToggle.container.style.pointerEvents = "none";
      // gsap.to(this.btnToggle.container, {
      //   alpha: 0,
      //   duration: .4,
      //   delay: 0,
      //   ease: Power2.easeOut
      // });

      gsap.to(this.content, {
        x: this.positions.xHide,
        duration: .6,
        delay: 0.2,
        ease: Ease.EASE_CUCHILLO_IN_OUT,
        onComplete: () => {
          this.afterHide();
        }
      });

      gsap.to(this.fakeButton, {
        alpha: 0,
        duration: .3,
        delay: 0,
        ease: Power3.easeInOut
      });
    }
  }

  static directHide__effect() {
    if (isSmartphone) {
      gsap.set(this.container, { alpha: 0 });
    } else {
      gsap.set(this.content, { x: this.positions.xHide });
      gsap.set(this.fakeButton, { alpha: 0 });
    }
  }

  static setupSizes() {
    this.sizes = {
      width: this.content.offsetWidth,
      height: this.content.offsetHeight,
    }

    this.positions = {
      xShow: 0,
      xHide: -this.sizes.width
    }

    //this.directHide();
  }

  static resize() {
    this.setupSizes();
  }
}
