import { Draggable } from "gsap/Draggable";

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics, isSmartphoneOnlyWidth } from '../_app/cuchillo/core/Basics';

class ScrollItem__Servicios extends VScroll_Item {
    _content;
    _btn;

    _click = () => {
        if (this.item.classList.contains('--hidden')) {
            this.item.classList.remove('--hidden');
        } else {
            this.item.classList.add('--hidden');
        }
    }

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        if (isSmartphoneOnlyWidth) {
            this._content = GetBy.class('block-servicios__content')[0];
            this.item.style.setProperty("--total-height", `${this._content.offsetHeight}px`);
            this.item.classList.add('--hidden');
            this._btn = GetBy.class('__view-more')[0];
            this._btn.addEventListener(Basics.clickEvent, this._click);
        }
    }

    resize(w, h) {
        super.resize(w, h);
    }

    loop() { }

    dispose() {
        super.dispose();
    }
}

Scroll._registerClass('BlockServicios', ScrollItem__Servicios);
