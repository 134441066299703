import { gsap } from "gsap";

import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Basics } from "../_app/cuchillo/core/Basics";
import { WinModalGallery } from "../windows/ModalGallery";

class ScrollItem__FullGallery extends VScroll_Item {
    _call;
    _slider;
    _images = [];

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================
    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);

        this._click = e => this.clickHandle(e);
        this._images = [...GetBy.class('__item', __link)];

        this.onShow = () => {
            for (let i = 0; i < this._images.length; i++) {
                this._images[i].addEventListener(Basics.clickEvent, this._click);
            }

            WinModalGallery.init(this.id);
        };

        this.onHide = () => {
            WinModalGallery.destroy();

            for (let i = 0; i < this._images.length; i++) {
                this._images[i].addEventListener(Basics.clickEvent, this._click)
            }
        };
    }

    clickHandle(e) {
        WinModalGallery.show(e.target.dataset.index);
    }

    resize(w, h) {
        super.resize(w, h);
        WinModalGallery.resize();
    }

    dispose() {
        WinModalGallery.destroy();
        super.dispose();
    }
}

Scroll._registerClass('full-gallery', ScrollItem__FullGallery);
