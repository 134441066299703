import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { GetBy } from '../_app/cuchillo/core/Element';

class ScrollItem__BookNow extends VScroll_Item {
  holder;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================

  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.holder = GetBy.class('__holder', __link)[0];

    this.onShow = () => { };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
}

Scroll._registerClass('BookNow', ScrollItem__BookNow);
