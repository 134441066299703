import { gsap, Power2 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import Win from '../_app/cuchillo/windows/Window';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { SliderScroll } from '../_app/cuchillo/components/SliderScroll';
import LoaderController from '../_app/cuchillo/loaders/LoaderController';

export default class ModalGallery extends Win {
    _slider;
    _wrapper;
    _holder;
    _items = [];

    constructor(__container, __id) {
        super(__container, __id);

        this._wrapper = GetBy.class('__slider', this.container)[0];
        this._holder = GetBy.class('__holder', this.container)[0];
        this.directHide();
    }

    init(__id) {
        const gallery = KORA_GALLERY[__id];
        if (!gallery) return;

        gallery.map(src => { this.addItem(src) });
        LoaderController._loaders.MediaLoader.addMoreMedia();

        this._slider = new SliderScroll(this.container, {
            interaction: true,
            hasSnap: true,
            hasScrollbar: true,
            itemClass: ScrollItem__SliderImages__Modal,
            domControls: GetBy.class("__controls", this.container)[0],
        });
    }

    destroy() {
        if (this._slider) {
            this._slider.dispose();
            this._slider = null;
        }
        this._holder.innerHTML = '';
    }

    addItem(src) {
        const img = document.createElement('img');
        img.setAttribute('data-item-load', '');
        img.setAttribute('data-scroll-slider-image', '');
        img.setAttribute('data-src', src);
        img.src = '/assets/images/blank.png';

        const item = document.createElement('div');
        item.classList.add('slider__item');
        item.setAttribute('scroll-slider-item', '');
        item.setAttribute('data-scroll-id', this.id);
        item.appendChild(img);

        this._holder.appendChild(item);
    }

    show(__i = 0) {
        super.show();

        if (this._slider) {
            this._slider.start();
            this._slider.gotoIndex(__i);
        }
    }

    show__effect(__d = 0) {
        gsap.to(this.container, { opacity: 1, duration: .5, ease: Power2.easeOut });
        gsap.to(this._wrapper, { opacity: 1, duration: .4, delay: .4, ease: Power2.easeOut, onComplete: () => { this.afterShow() } });
    }

    hide__effect(__d = 0) {
        gsap.to(this._wrapper, { opacity: 0, duration: .2, delay: .1, ease: Power2.easeOut });
        gsap.to(this.container, { opacity: 0, duration: .2, delay: .2, ease: Power2.easeOut, onComplete: () => { this.afterHide() } });
    }

    afterHide() {
        super.afterHide();
        if (this._slider) this._slider.stop();
    }

    directHide() {
        gsap.set(this.container, { opacity: 0 });
        gsap.set(this._wrapper, { opacity: 0 });

        super.directHide();
    }

    resize() {
        super.resize();
        if (this._slider) this._slider.resize();
    }
}

export const WinModalGallery = new ModalGallery(GetBy.id('ModalGallery'), 'ModalGallery');

class ScrollItem__SliderImages__Modal extends VScroll_Item {
    hasHiddenEnabled = false;
    info;

    //==================================================================================================================
    //          CONSTRUCTOR
    //==================================================================================================================

    constructor(__link, __index, __scroller) {
        super(__link, __index, __scroller);
    }

    //==================================================================================================================
    //          PUBLIC
    //==================================================================================================================

    //update () {
    /* if (this.progress > .5) {
       const p = Math.max(0, Maths.normalize(1, .5, this.progress));
       this._x = this._x + ((Metrics.WIDTH * .5) * p);
     }*/
    /* super.update();
  }

  mouseOver () { }
  mouseDown () { }
  mouseUp () { }

  show () {
    super.show();
  }

  hide () {
    super.hide();
  }*/
}
