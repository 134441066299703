import flatpickr from "flatpickr";
import { Functions } from "../_app/cuchillo/utils/Functions";
import { Sizes } from "../_app/cuchillo/core/Sizes";
import { GetBy } from "../_app/cuchillo/core/Element";
import { Basics } from "../_app/cuchillo/core/Basics";
import { English } from "flatpickr/dist/l10n/default"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { French } from "flatpickr/dist/l10n/fr.js"

export class KoraCalendars {
  static _calendars = {};
  static get localization() {
    switch (Basics.language) {
      case "es": return Spanish;
      case "fr": return French;
      default: return English;
    }
  };

  static init() {
    this.getCalendars();
  };

  static getCalendars() {
    [...document.querySelectorAll("[data-calendar]")].map(dom => {
      const id = Functions.getId(dom);
      const calendar = new Calendar(dom);
      calendar.init();
      this._calendars[id] = calendar;
    });
  };

  static dispose() {
    Object.entries(this._calendars).map((item) => { item[1].dispose() });
    this._calendars = {};
  }

  static resize() {
    Object.entries(this._calendars).map((item) => { item[1].resize() });
  }
};

export class SimpleCalendar {
  container;
  _calendar;
  _mode = '';
  _defaults;

  constructor(dom) {
    this.container = dom;

    this._defaults = {
      dateFormat: "d/m/Y",
      minDate: "today",
      appendTo: dom,
      showMonths: 3,
      inline: true,
      timzone: "auto",
      locale: KoraCalendars.localization,
      ignoredFocusElements: [window.document.body],
      onValueUpdate: function (selectedDates, dateStr, instance) {
        // Aquí puedes manejar los eventos de actualización de valor
      },
      onChange: (selectedDates, dateStr, instance) => {
        if (selectedDates.length > 1 && instance.config.minRange) {
          var range = instance.formatDate(selectedDates[1], 'U') - instance.formatDate(selectedDates[0], 'U');
          range = range / 86400;

          if (range < instance.config.minRange) {
            let newEndDate = new Date(
              selectedDates[0].getTime() + 86400000 * this._defaults.minRange
            );

            instance.setDate([selectedDates[0].toUTCString(), newEndDate.toUTCString()]);
          }
        }
      },
    }

    if (this.container.getAttribute("data-minimum-days")) {
      this._defaults.minRange = Number(this.container.getAttribute("data-minimum-days"));
    }

    if (this.container.getAttribute("data-valid-days")) {
      const arrayDates = this.convertirFechas(this.container.getAttribute("data-valid-days")).split(",");
      this._defaults.minDate = arrayDates[0];
      this._defaults.enable = this.convertirFechas(this.container.getAttribute("data-valid-days")).split(",");
    }

    if (this.container.getAttribute("data-basic") == null) {
      this._defaults.mode = "range";
    }
  }

  convertirFechas(fechasString) {
    // Expresión regular para encontrar fechas en formato yyyy-mm-dd
    const regex = /\b\d{4}-\d{2}-\d{2}\b/g;

    // Función de reemplazo para cada coincidencia de fecha encontrada
    function reemplazarFecha(match) {
      // Divide la fecha en sus partes (año, mes, día)
      const [year, month, day] = match.split('-');
      // Formatea la fecha como dd-mm-yyyy
      return `${day}-${month}-${year}`;
    }

    // Aplica la función de reemplazo a todas las coincidencias de fechas
    const fechas_dd_mm_yyyy = fechasString.replace(regex, reemplazarFecha);
    return fechas_dd_mm_yyyy;
  }

  init() {
    this.resize();
  }

  getDates() {
    const dates = [];

    if (this._calendar.selectedDates[0]) {
      const firstSelectedDate = this._calendar.selectedDates[0];
      const formattedDate = firstSelectedDate.getDate() + '/' + (firstSelectedDate.getMonth() + 1) + '/' + firstSelectedDate.getFullYear();

      dates[0] = formattedDate;

      if (this._calendar.selectedDates[1]) {
        const secondSelectedDate = this._calendar.selectedDates[1];
        const formattedDateTwo = secondSelectedDate.getDate() + '/' + (secondSelectedDate.getMonth() + 1) + '/' + secondSelectedDate.getFullYear();

        dates[1] = formattedDateTwo;
      } else {
        dates[1] = formattedDate;
      }
    }

    return dates;
  }

  createCalendar() {
    this._calendar = flatpickr(this.container, this._defaults);
  }

  dispose() {
    if (this._calendar) {
      this._calendar.destroy();
      this._calendar = null;
    }
  }

  resize() {
    const isMobile = window.innerWidth <= Sizes.TABLET_PORTRAIT;
    const isTablet = window.innerWidth <= Sizes.TABLET_LANDSCAPE && !isMobile;

    if (this._mode !== 'mobile' && isMobile) {
      this._mode = 'mobile';
      this._defaults.showMonths = 1;
      this.dispose();
      this.createCalendar();
    } else if (this._mode !== 'tablet' && isTablet) {
      this._mode = 'tablet';
      this._defaults.showMonths = 2;
      this.dispose();
      this.createCalendar();
    } else if (this._mode !== 'desktop' && !isMobile && !isTablet) {
      this._mode = 'desktop';
      this._defaults.showMonths = 3;
      this.dispose();
      this.createCalendar();
    }
  }
};

class Calendar extends SimpleCalendar {
  _cta;

  constructor(dom) {
    super(dom);

    this._click = () => this.click();
    this._cta = GetBy.id(dom.dataset.buttonId);
  }

  click() {
    let nights = 1;
    let url = `/bookingstep1/?idtokenprovider=${this._cta.dataset.hotelId}&lang=${this._cta.dataset.lang}`;

    if (this._calendar.selectedDates[0]) {
      const firstSelectedDate = this._calendar.selectedDates[0];
      const formattedDate = firstSelectedDate.getDate() + '/' + (firstSelectedDate.getMonth() + 1) + '/' + firstSelectedDate.getFullYear();

      if (this._calendar.selectedDates[1]) {
        const secondSelectedDate = this._calendar.selectedDates[1];

        const diffMilliseconds = secondSelectedDate - firstSelectedDate;
        nights = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));
      }

      url += `&checkin=${encodeURIComponent(formattedDate)}&nights=${nights}`;
    }

    if (this._cta.dataset.offerId) {
      url += `&idoffers=${this._cta.dataset.offerId}`;
    }

    window.location.href = url;
  }

  createCalendar() {
    super.createCalendar();
    this._cta.addEventListener(Basics.clickEvent, this._click);
  }

  dispose() {
    super.dispose();

    this._cta.removeEventListener(Basics.clickEvent, this._click);
  }
};
