import CustomLoader from './CustomLoader';
import { Display } from '../display/Display';
import { C } from '../core/Element';
import { Scroll } from '../scroll/Scroll';

export default class LazyLoader extends CustomLoader {
    static NORMAL = 'normal';
    static BACKGROUND = 'bg';

    isBackground = true;
    data = [];

    _manifest = [];
    _running = false;

    opts = {
        offset: 500
    };

    get offset() {
        return (window.innerHeight + this.opts.offset) - Scroll.y;
    }

    constructor(__opts = {}) {
        super();

        this.id = 'LazyLoader';

        this.opts = {
            ...this.opts,
            ...__opts
        };
    }

    getMedia() {
        let tClass = this;
        let typeMedia = 'data-item-lazyload';
        let item;

        C.forEach('[' + typeMedia + ']', function (el, i) {
            if (el.tagName.toUpperCase() === 'IMG') {
                item = new Display.image(el);
            } else if (el.tagName.toUpperCase() === 'VIDEO') {
                item = new Display.video(el);
            } else {
                item = new Display.bg(el);
            }

            el.removeAttribute("data-item-lazyload");
            tClass.add(item);
        }.bind(this));
    }

    add(__item) {
        this._manifest.push(__item);
    }

    initBackground() {
        this.getMedia();
        this._manifest = this._manifest.sort((a, b) => {
            if (a._yShow < b._yShow) return -1;
            if (a._yShow > b._yShow) return 1;
            return 0;
        }).reverse();
    }

    loop() {
        let i = this._manifest.length;
        while (i--) {
            if (this._manifest[i]._yShow <= this.offset) {
                const item = this._manifest[i];
                this.data.push(item);
                item.load();

                this._manifest.splice(i, 1);
            } else {
                return;
            }
        }
    }

    dispose() {
        for (let i = 0, j = this._manifest.length; i < j; i++) {
            this._manifest[i].dispose();
        }

        for (let i = 0, j = this.data.length; i < j; i++) {
            this.data[i].dispose();
        }

        this.data = [];
    }

    end() {
        this._running = false;
        if (this.onComplete) this.onComplete(this.id);
    }

    resize() {
        for (let i = 0, j = this._manifest.length; i < j; i++) {
            this._manifest[i].resize();
        }
    }

    reset() {
        this._manifest = [];
    }
}
